import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { FormField, Input } from 'src/shared';
import { CURRENCIES, PAYMENT_ERRORS, PAYMENT_SYSTEMS, i18, trb } from 'src/Utilities';
import { PMCActions } from 'src/Redux';
import { checkIsFirstTwoLetters } from 'src/utils';
import { IPaymentError } from 'src/Containers/Payments/components';
import { BICS_SET_1 } from 'src/constants';

interface IProps {
  errorsPVR?: Array<IPaymentError> | null;
}

export const FieldPayeeAccount = ({ errorsPVR }: IProps) => {
  const dispatch = useDispatch();
  const { PVR } = useSelector((state: any) => state.PMCReducers);
  const { setFieldValue, values }: FormikContextType<any> = useFormikContext<any>();

  useEffect(() => {
    const onCheckIban = async () => {
      await setFieldValue('Payment.CheckBeneficiaryAccountNumber', false);
      await handleValidationIBAN(values.Payment.BeneficiaryAccountNumber);
    };

    if (values?.Payment?.CheckBeneficiaryAccountNumber) {
      onCheckIban();
    }
  }, [values?.Payment?.CheckBeneficiaryAccountNumber]);

  const [error, setError] = useState<string | null>(null);

  const isCurrencyEUR = (currency: string) => {
    return currency === CURRENCIES.EUR || currency?.indexOf(`_${CURRENCIES.EUR}`) !== -1;
  };

  const handleValidationIBAN = async (value: string) => {
    const formattedValue = value.replaceAll(' ', '');
    await setFieldValue('Payment.ValidationPayeeIBAN', formattedValue);

    if (checkIsFirstTwoLetters(formattedValue) && value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await dispatch(PMCActions.setTarget2Payment(null));

      const res = await dispatch(PMCActions.postCheckIBANMember(value.replaceAll(' ', '')) as any);

      if (res?.payload?.IsValid) {
        setError(null);

        await setFieldValue('Payment.BIC', res.payload.Bic?.BIC);
        await setFieldValue('Payment.BankName', res.payload.Bic?.BankName);
        await setFieldValue('Payment.RecipientBankCountry', res.payload.Country?.Alpha2);

        const { Participant } = res.payload;

        const paymentSystem =
          isCurrencyEUR(values.Payment.Currency) && Participant && !Participant?.SepaSCT && !Participant?.SepaInst && !Participant?.SepaSDD
            ? PAYMENT_SYSTEMS.TARGET2
            : PAYMENT_SYSTEMS.SEPA;

        await setFieldValue('Payment.PaymentSystem', paymentSystem);

        if (Participant?.SepaInst && (!PVR || (PVR && !errorsPVR?.find((el) => el.ErrorCode === PAYMENT_ERRORS.SEPA_INST)))) {
          await setFieldValue('Payment.PaymentSystem', PAYMENT_SYSTEMS.INST);
        }

        if (BICS_SET_1.includes(res.payload?.Bic?.BIC)) {
          await setFieldValue('Payment.PaymentSystem', PAYMENT_SYSTEMS.SEPA);
        }
      } else {
        setError(i18.PaymensValidations.InvalidIBAN);
      }
    } else {
      if (value) {
        await setFieldValue('Payment.PaymentSystem', PAYMENT_SYSTEMS.TARGET2);
        await dispatch(PMCActions.setTarget2Payment({ currency: CURRENCIES.EUR, target2: true, value: value }) as any);
      }

      setError(null);
    }
  };

  return (
    <FormField
      name="Payment.BeneficiaryAccountNumber"
      label={trb(i18.Labels.PayeeAccount)}
      error={error}
    >
      <Input
        id="Payment.BeneficiaryAccountNumber"
        handleBlur={handleValidationIBAN}
        handleFocus={() => setError(null)}
        trimOnBlur="all"
      />
    </FormField>
  );
};
