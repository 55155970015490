import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { UACActiveUser } from 'src/Redux';

import { FormField, Input, Select, ToggleButton } from 'src/shared';
import { classifikatorsMap, getClassificators, i18, translationGroups, trb } from '../../../../../Utilities';
import { PAYER_PAYEE_TYPES } from 'src/constants';
import { IPaymentNewValues } from '../../interfaces';
import { FieldIdentificationType } from '../FieldIdentificationType';

export const PayerInformation = () => {
  const { values }: { values: IPaymentNewValues } = useFormikContext();
  const type = useSelector(UACActiveUser)?.Type;
  const CISO = getClassificators()?.CISO || {};
  const countries = classifikatorsMap(CISO);

  return (
    <div>
      <FormField
        name="Payment.PayerType"
        label={trb(i18.Labels.PayerType)}
        floating={false}
      >
        <ToggleButton
          options={PAYER_PAYEE_TYPES.filter((item) => !type || !item.type || item.type === type).map((item) => ({
            value: item.value,
            label: trb(translationGroups.PayerPayeeTypes + item.label),
          }))}
        />
      </FormField>
      {values.Payment.PayerType ? (
        <>
          <FieldIdentificationType
            name="Payment.PayerIdentTypeCode"
            type={values.Payment.PayerType}
            label="PayerIdentificationType"
          />
          <FormField
            name="Payment.PayerIdentTypeValue"
            label={trb(i18.Labels.PayerIdentificationCode)}
          >
            <Input />
          </FormField>
          <FormField
            name="Payment.PayerAddress"
            label={trb(i18.Labels.PayerAddress)}
          >
            <Input />
          </FormField>
          <FormField
            name="Payment.PayerCountryCode"
            label={trb(i18.Labels.PayerCountryCode)}
          >
            <Select options={countries} />
          </FormField>
        </>
      ) : null}
    </div>
  );
};
