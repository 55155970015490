import React, { useEffect } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Alert, Button, Space } from 'antd';
import { useSelector } from 'react-redux';

import { DepositsValidation, FeeModule, FeePaymentValidation, FormField, FormSpySwitch, NewPaymentValidation } from '../';
import { checkJsonExist, checkJsonProp, composeValidatorsJSON, i18, Payment, trb } from 'src/Utilities';
import { DepositForm, FeePaymentForm, ForexExchangeForm, OwnAccountsForm } from './';
import { OwnAccountsValidation } from './FormValidations/OwnAccountsValidation';
import { ForexValidation } from './FormValidations/ForexValidation';
import { InternationalPaymentValidation } from './FormValidations/InternationalPaymentValidation';
import { history } from 'src/Config';
import { AlertNotification, ScrollToFieldErrorRFF } from 'src/shared';
import { PaymentError } from 'src/Containers/Payments/components';

export const FormEntrySwitch = (props) => {
  const AMCReducers = useSelector((state) => state.AMCReducers);

  let currentForm = null,
    initialData = {};

  switch (props.name) {
    case 'deposits':
      currentForm = DepositForm(props);
      initialData = {
        ProductTypeID: 4,
        SubProductID: 38,
        Interest: 0,
        AutoResume: 'WithInterest',
        CurrencyID: 2,
      };
      break;
    case Payment.OWN_ACCOUNTS:
      currentForm = OwnAccountsForm(props);
      initialData = {
        Payment: {
          ...props.data?.Payment,
          Template: props.templateId,
          PaymentType: 4,
        },
      };
      break;
    case Payment.FEE:
      initialData = {
        Payment: {
          Template: props.templateId,
          PaymentType: 2,
          IsPurposeSelected: true,
          IsSEPAPaymentTypeSelected: true,
          SEPAPaymentType: 'UBIL',
          Purpose: null,
        },
        FeeModuleId: null,
      };
      currentForm = FeePaymentForm(props);
      break;

    case Payment.FOREX_EXCHANGE:
      initialData = {
        Payment: {
          ...props?.data?.Payment,
        },
      };
      currentForm = ForexExchangeForm(props);
      break;
    default:
      console.log('Form type not found');
  }

  const currentFormSpy = (feeModuleId, index, values, form) => {
    if (props.name === Payment.FEE) {
      values.FeeModuleId = feeModuleId;
      return (
        <FeeModule
          key={index}
          form={form}
          values={values}
          feeModuleId={feeModuleId}
          feePaymentHistory={props.feePaymentHistory}
        />
      );
    } else {
      return (
        <FormSpy
          key={index}
          name={props.name}
          templates={props.PaymentTemplates}
          data={{ ...initialData }}
          component={FormSpySwitch}
          PVR={props.PVR}
          clearErrors={props.clearErrors}
          templateId={props.templateId}
          forexPayments={props.forexPayments?.InitialAccounts}
          forexTimerValues={props.forexTimerValues}
        />
      );
    }
  };
  /**
   * Checks for valuesArr items in values like 'Payments.Currency' and returns values merged into string
   * if none of the values were found returns null
   * @param valuesArr items to search for
   * @param values values to search in like an object
   * @returns {null|*}
   */
  const getTitleValue = (valuesArr, values) => {
    const result = valuesArr.reduce((curr, item) => {
      return (curr += checkJsonExist(values, item) ? checkJsonProp(values, item) : item);
    }, '');
    return result === valuesArr.join('') ? null : result;
  };

  const [submitCount, setSubmitCount] = React.useState(0);

  useEffect(() => {
    if (props.DepositAmountNotValid) {
      window.scrollTo({
        top: 100,
        behavior: 'smooth',
      });
    }
  }, [props?.DepositAmountNotValid]);

  if (!props.name) {
    return null;
  }

  if (!currentForm) {
    history.push('/');
    return null;
  }

  return (
    <div className={`mb-0 form ${props.className ?? ''} ${props.name === 'deposits' && !props.DepositAmountNotValid && !props.errors ? 'mt-0 mt-md-4' : ''}`}>
      {props?.infoData && props.infoData}
      <Form
        onSubmit={props.submit}
        decorators={props.decorator}
        keepDirtyOnReinitialize={true}
        initialValues={{ ...initialData }}
        validate={(values) => {
          switch (props.name) {
            case 'deposits':
              return DepositsValidation({ values, CIPTR: props.CIPTR });
            case Payment.FEE:
              return FeePaymentValidation(values, props);
            case Payment.NEW:
              return NewPaymentValidation(values, props);
            case Payment.OWN_ACCOUNTS:
              return OwnAccountsValidation(values, props);
            case Payment.FOREX_EXCHANGE:
              return ForexValidation(values, props, AMCReducers);
            case Payment.INTERNATIONAL:
              return InternationalPaymentValidation(values, props, props.forexPayments?.InitialAccounts);
            default:
              return null;
          }
        }}
        render={({ handleSubmit, form, submitting, values, errors }) => {
          return (
            <form
              id={`EntrySwitchForm${props.name}`}
              className="eb row"
              onSubmit={async (e) => {
                setSubmitCount((prevState) => prevState + 1);
                await handleSubmit(e);
              }}
            >
              {props.name !== Payment.FEE && currentFormSpy()}
              {values.note && (
                <Alert
                  type="warning"
                  message={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: values.note.replace(/\n/g, '<br />'),
                      }}
                    />
                  }
                  className="mb-3 col-12"
                />
              )}
              <ScrollToFieldErrorRFF
                errors={errors}
                submitCount={submitCount}
              />
              {props.DepositAmountNotValid && (
                <div>
                  <AlertNotification
                    message={trb(i18.NotificationTitles.DepositAmountNotValid, { minSum: props.DepositAmountNotValid.MinimumAmountDeposit })}
                    type="error"
                    alertClassName="py-3 mb-3"
                  />
                </div>
              )}

              <PaymentError errors={props.errors} />

              {currentForm.title && <div className="form-title col-12">{trb(currentForm.title)}</div>}
              {currentForm.parts.map((partItem, partIndex) => {
                let a = checkJsonExist(values, partItem.visible);
                const b = checkJsonProp(values, partItem.visible);
                if (partItem.visible === 'Payment.IsAdditionalDataSelected') {
                  if (!a) {
                    a = !a;
                  }
                }
                return !a || (b && b === true) ? (
                  partItem.moduleComponent === 'FeeModule' ? (
                    currentFormSpy(values.Payment && values.Payment.FeeProvider, partIndex, values, form)
                  ) : (
                    <div
                      className={`${partItem.className ? partItem.className : 'col-12 col-md-6'} form-part`}
                      key={partIndex}
                    >
                      {partItem.title && <div className="form-part-title">{trb(partItem.title)}</div>}
                      {partItem.fields.map((item, index) => {
                        return item && (!item.visible || checkJsonProp(values, item.visible)) ? (
                          <FormField
                            key={`${item?.name}-${index}`}
                            className={item.className}
                            classNameTitle={item.classNameTitle}
                            classNameField={item.classNameField}
                            classNameError={item.classNameError}
                            classNameNumber={item.classNameNumber}
                            title={item?.titleFromInputValues ? getTitleValue(item?.titleFromInputValues, values) : item.title}
                            name={item.name}
                            component={item.component}
                            componentOptions={item.componentOptions}
                            formatNumber={item.formatNumber}
                            inline={item.inline}
                            options={item.optionsFunction ? item.optionsFunction(item.optionsFunctionParams, values, item.options) : item.options ? item.options : []}
                            optionsLabel={item.optionsLabel}
                            optionsValue={item.optionsValue}
                            optionsKey={item.optionsKey}
                            optionsDisabled={item.optionsDisabled}
                            limitElements={item.limitElements}
                            onSearch={item.onSearch}
                            translate={item.translate}
                            validate={item.validateCompose ? composeValidatorsJSON(item.validate, props.form, values) : item.validate}
                            disabled={item.disabledFunction ? item.disabledFunction(item.disabledFunctionParams, values, item.options) : item.disabled || props.submitting}
                            loading={item.loading}
                            maxLength={item.maxLength}
                            html={item.html}
                            type={item.type}
                            defaultValue={item.defaultValue}
                            notify={submitting ? null : item.notify}
                            error={submitting ? null : item.error}
                            noFilter={item.noFilter}
                            highlightOne={item.highlightOne}
                            small={item.small}
                            customChecks={item.customChecks}
                            optionsColor={item.optionsColor}
                            hightlight={item.hightlight}
                            allowClear={item.allowClear}
                            helperOnChange={item.helperOnChange}
                            onBlur={item.onBlur}
                            minDate={item.minDate}
                            maxDate={item.maxDate}
                            currency={values?.Payment?.Currency}
                            allowNegative={item.allowNegative}
                            reactComponent={item.reactComponent}
                          />
                        ) : null;
                      })}
                    </div>
                  )
                ) : null;
              })}
              <div className="text-right">
                <Space>
                  {props.buttons &&
                    props.buttons.map((item, index) => (
                      <Button
                        id="PaymentSubmitButton"
                        key={index}
                        onClick={item.func}
                        {...item.props}
                      >
                        {trb(item.title)}
                      </Button>
                    ))}
                </Space>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
