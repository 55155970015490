import React from 'react';

const getFieldErrorNamesRFF = (formikErrors: object) => {
  const transformObjectToDotNotation = (obj: any, prefix = '', result: string[] = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (!value) {
        return;
      }

      const nextKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'object' && !value.type) {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

interface IProps {
  errors: any;
  submitCount: number;
}

export const ScrollToFieldErrorRFF = ({ errors, submitCount }: IProps) => {
  React.useEffect(() => {
    const fieldErrorNames = getFieldErrorNamesRFF(errors);

    if (fieldErrorNames.length <= 0) {
      return;
    }

    const elements = document.getElementsByName(fieldErrorNames[0]);

    if (!elements || elements.length <= 0) {
      return;
    }

    elements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};
