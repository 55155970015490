import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { FormField, Select } from 'src/shared';
import { PAYMENT_SYSTEMS, i18, trb } from 'src/Utilities';
import { IPaymentNewValues } from './../../interfaces';
import { PMCActions } from 'src/Redux';
import { getTrimmedValue } from '../../../../../utils';

export const FieldTemplate = () => {
  const dispatch = useDispatch();
  const { setFieldValue, resetForm, values } = useFormikContext();
  const { PTR } = useSelector((state: any) => state.PMCReducers);
  const { state } = useLocation();

  const handleChangeTemplate = async (id: string) => {
    if (id) {
      const current = PTR?.PaymentTemplates?.find((e: any) => e.TemplateID === id);
      await dispatch(PMCActions.clearPVR() as any);

      await setFieldValue('Payment.Recipient', current.Payment.Recipient);
      await setFieldValue('Payment.BeneficiaryAccountNumber', getTrimmedValue(current.Payment.BeneficiaryAccountNumber, 'all'));
      await setFieldValue(
        'Payment.Purpose',
        current.Payment.IsPurposeSelected || current.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? current.Payment.Purpose : current.Payment.ReferenceNo
      );

      await setFieldValue('Payment.IsPurposeSelected', !current.Payment.ReferenceNo);

      await setFieldValue('Payment.BeneficiaryAddress', current.Payment.BeneficiaryAddress);
      await setFieldValue('Payment.BeneficiaryCountryCode', current.Payment.BeneficiaryCountryCode);
      await setFieldValue('Payment.BeneficiaryCity', current.Payment.BeneficiaryCity);
      await setFieldValue('Payment.BeneficiaryPostCode', current.Payment.BeneficiaryPostCode);
      await setFieldValue('Payment.CorrespondentBankBic', current.Payment.CorrespondentBankBic);
      await setFieldValue('Payment.BIC', current.Payment.BIC);
      await setFieldValue('Payment.BankName', current.Payment.BankName);
      await setFieldValue('Payment.RecipientBankCountry', current.Payment.RecipientBankCountry);
      await setFieldValue('Payment.PaymentSystem', current.Payment.PaymentSystem);
      await setFieldValue('Payment.CustomersCode', current.Payment.CustomersCode);
      await setFieldValue('Payment.CheckBeneficiaryAccountNumber', true);
      await setFieldValue('Payment.IsAdditionalDataSelected', current.Payment.IsAdditionalDataSelected);

      if (current.Payment.IsAdditionalDataSelected) {
        if (current.Payment.PayerType) {
          await setFieldValue('Payment.PayerType', current.Payment.PayerType);
          await setFieldValue('Payment.PayerIdentTypeCode', current.Payment.PayerIdentTypeCode);
          await setFieldValue('Payment.PayerIdentTypeValue', current.Payment.PayerIdentTypeValue);
          await setFieldValue('Payment.PayerAddress', current.Payment.PayerAddress);
          await setFieldValue('Payment.PayerCountryCode', current.Payment.PayerCountryCode);
        }

        if (current.Payment.InitPayerType) {
          await setFieldValue('Payment.InitPayerType', current.Payment.InitPayerType);
          await setFieldValue('Payment.InitPayerName', current.Payment.InitPayerName);
          await setFieldValue('Payment.InitPayerIdentTypeCode', current.Payment.InitPayerIdentTypeCode);
          await setFieldValue('Payment.InitPayerIdentTypeValue', current.Payment.InitPayerIdentTypeValue);
        }

        if (current.Payment.PayeeType) {
          await setFieldValue('Payment.PayeeType', current.Payment.PayeeType);
          await setFieldValue('Payment.PayeeIdentTypeCode', current.Payment.PayeeIdentTypeCode);
          await setFieldValue('Payment.PayeeIdentTypeValue', current.Payment.PayeeIdentTypeValue);
          await setFieldValue('Payment.PayeeAddress', current.Payment.PayeeAddress);
        }

        if (current.Payment.FinalPayeeType) {
          await setFieldValue('Payment.FinalPayeeType', current.Payment.FinalPayeeType);
          await setFieldValue('Payment.FinalPayeeName', current.Payment.FinalPayeeName);
          await setFieldValue('Payment.FinalPayeeIdentTypeCode', current.Payment.FinalPayeeIdentTypeCode);
          await setFieldValue('Payment.FinalPayeeIdentTypeValue', current.Payment.FinalPayeeIdentTypeValue);
        }

        await setFieldValue('Payment.SEPAPaymentType', current.Payment.SEPAPaymentType);
      }
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    const setTemplate = async (templateId: string) => {
      await setFieldValue('Payment.Template', templateId);
      handleChangeTemplate(templateId);
    };
    if (state?.templateId && !(values as IPaymentNewValues)?.Payment?.Template) {
      setTemplate(state.templateId);
    }
  }, []);

  const filterPaymentTemplates = (templates: { PaymentType: number }[]) => {
    return templates.filter((e) => e.PaymentType !== 7);
  };

  return (
    <div>
      <FormField
        name="Payment.Template"
        label={trb(i18.Labels.PaymentTemplates)}
      >
        <Select
          options={filterPaymentTemplates(PTR?.PaymentTemplates).map((item: any) => ({
            label: <>{item.TemplateName}</>,
            value: item.TemplateID,
            labelText: item.TemplateName,
          }))}
          handleChange={(value: string) => handleChangeTemplate(value)}
        />
      </FormField>
    </div>
  );
};
