import { useState } from 'react';
import { Typography } from 'antd';
import { AlertNotification } from 'src/shared';
import { i18, trb } from 'src/Utilities';
import { ILoginMessages } from './interfaces';

export const LoginMessage = ({ Message, MsgType }: ILoginMessages) => {
  const { Paragraph, Text } = Typography;
  const [expandable, setExpandable] = useState(true);
  const [isExpandable, setIsExpandable] = useState(false);

  return (
    <div className="mb-3">
      <AlertNotification
        description={
          <div>
            <Paragraph
              ellipsis={{
                rows: expandable ? 2 : 999,
                expandable,
                symbol: <></>,
                onEllipsis: (expand) => {
                  if (!isExpandable) {
                    setIsExpandable(expand);
                  }
                },
              }}
              className={`mb-0 ${MsgType === 1 ? 'info_message' : 'error_message'}`}
            >
              {Message}
            </Paragraph>
            {isExpandable && (
              <Text
                className={MsgType === 1 ? 'info_message_button' : 'error_message_button'}
                onClick={() => setExpandable((p) => !p)}
              >
                {trb(i18.Labels[expandable ? 'ShowMore' : 'ShowLess'])}
              </Text>
            )}
          </div>
        }
        alertClassName={'mb-2 p-3'}
        type={MsgType === 1 ? 'info' : 'error'}
      />
    </div>
  );
};
