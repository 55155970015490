import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { FormField, Input } from '../../../../../shared';
import { i18, trb } from '../../../../../Utilities';
import { checkIsFirstTwoLetters } from '../../../../../utils';
import { PMCActions } from '../../../../../Redux';

export const FieldPayeeAccount = () => {
  const dispatch: any = useDispatch();
  const [error, setError] = useState<string | null>(null);

  const handleValidationIBAN = async (value: string) => {
    const formattedValue = value.replaceAll(' ', '');

    if (checkIsFirstTwoLetters(formattedValue) && value) {
      const resValid = await dispatch(PMCActions.checkIbanValid({ iban: value }));

      if (!resValid?.payload?.ibanValid) {
        setError(i18.PaymensValidations.InvalidIBAN);
      } else {
        setError(null);
      }
    } else {
      setError(null);
    }
  };

  return (
    <FormField
      name="Payment.BeneficiaryAccountNumber"
      label={trb(i18.Labels.PayeeAccount)}
      error={error}
    >
      <Input
        handleBlur={handleValidationIBAN}
        handleFocus={() => setError(null)}
        trimOnBlur="all"
      />
    </FormField>
  );
};
