import { useEffect, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { i18, isSafari14OrOlder, Logout, secondsToMinuteString, trb } from '../../Utilities';
import { Modal } from '../../shared';

const timeout = parseInt(import.meta.env.VITE_IDLE_TIME);
const promptBeforeIdle = parseInt(import.meta.env.VITE_IDLE_MODAL);

export const IdleTimer = () => {
  const [remaining, setRemaining] = useState<number>(timeout);
  const [open, setOpen] = useState<boolean>(false);

  const oldSafari = isSafari14OrOlder();

  const onIdle = async () => {
    await Logout();
    setOpen(false);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate, isPrompted } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    crossTab: true,
    timers: oldSafari ? undefined : workerTimers,
  });

  useEffect(() => {
    const timerWorker = new Worker(`${import.meta.env.VITE_ROOT}worker.js`);
    timerWorker.postMessage('start');
    timerWorker.onmessage = () => {
      const remainingTime = Math.ceil(getRemainingTime() / 1000);

      setRemaining(remainingTime);

      if (isPrompted()) {
        document.title = `${secondsToMinuteString(remainingTime)} | ${import.meta.env.VITE_TITLE}`;
      } else {
        document.title = import.meta.env.VITE_TITLE;
      }
    };

    return () => {
      timerWorker.terminate();
      document.title = import.meta.env.VITE_TITLE;
    };
  }, [getRemainingTime, isPrompted]);

  const handleStillHere = () => {
    setOpen(false);
    activate();
  };

  const handleLogout = async () => {
    await Logout();
  };

  return (
    <Modal
      centered={true}
      open={open}
      onOk={handleStillHere}
      onCancel={handleLogout}
      closable={false}
      maskClosable={false}
      destroyOnClose={true}
      okText={trb(i18.Buttons.ContinueWork)}
      cancelText={trb(i18.Buttons.Logout)}
      className="bbs_logout_modal"
    >
      <div className="d-flex">
        <div className="pl-3 pr-4 pt-2">
          <QuestionCircleOutlined style={{ fontSize: '24px', color: 'orange' }} />
        </div>
        <div className="d-flex flex-column">
          <div className="pt-2">
            <h5>{trb(i18.ModalTitles.SessionGoesEnd)}</h5>
          </div>
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: trb(i18.Messages.SessionGoesEndTimeLeft2, {
                  timeLeft: secondsToMinuteString(remaining),
                }),
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
