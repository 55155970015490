import {
  BorderOutlined,
  DownloadOutlined,
  ExpandOutlined,
  FullscreenOutlined,
  LeftOutlined,
  PrinterOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SplitCellsOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Skeleton, Space, Tooltip, Typography } from 'antd';
import screenfull from 'screenfull';

import 'antd/dist/reset.css';
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { trb } from '../../Utilities';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

interface PDFViewerProps {
  url: string;
  documentName: string;
  styleWrapper?: CSSProperties;
  disableDownload?: boolean;
  disablePrint?: boolean;
}

export const PDFViewer = ({ url, styleWrapper, documentName, disableDownload, disablePrint }: PDFViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const [rotation, setRotation] = useState<number>(0);
  const [isMultiPage, setIsMultiPage] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const viewerRef = useRef<HTMLDivElement>(null);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadPDF = async () => {
      try {
        const pdf = await pdfjs.getDocument(url).promise;
        setNumPages(pdf.numPages);
        setLoading(false);
        setPageNumber(1);

        if (pdf.numPages > 20) {
          setIsMultiPage(false);
        }

        pageRefs.current = new Array(pdf.numPages).fill(null);

        setTimeout(() => {
          handleFit();
        }, 10);
      } catch (err) {
        console.error('Error loading PDF:', err);
        setError('Failed to load PDF. Please try again.');
        setLoading(false);
      }
    };

    loadPDF();
  }, [url]);

  useEffect(() => {
    if (numPages > 0) {
      handleFit();
    }
  }, [numPages]);

  const isMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const handlePrint = () => {
    if (isMobile()) {
      downloadPDF();

      return;
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = url;
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow?.focus();
        iframe.contentWindow?.print();
      }, 1);
    };

    iframe.onerror = () => {
      console.error('Failed to load PDF for printing');
      document.body.removeChild(iframe);
    };
  };

  const changePage = (offset: number) => {
    setPageNumber((prevPageNumber) => {
      const newPageNumber = Math.min(Math.max(prevPageNumber + offset, 1), numPages);
      scrollToPage(newPageNumber);

      return newPageNumber;
    });
  };

  const calculateVisiblePage = useCallback(() => {
    if (!containerRef.current) {
      return;
    }

    const containerTop = containerRef.current.scrollTop;
    const containerBottom = containerTop + containerRef.current.clientHeight;
    let maxVisibleHeight = 0;
    let mostVisiblePage = 1;

    pageRefs.current.forEach((pageRef, index) => {
      if (pageRef) {
        const rect = pageRef.getBoundingClientRect();
        const pageTop = rect.top - containerRef.current!.offsetTop + containerTop;
        const pageBottom = pageTop + rect.height;

        const visibleTop = Math.max(containerTop, pageTop);
        const visibleBottom = Math.min(containerBottom, pageBottom);
        const visibleHeight = Math.max(0, visibleBottom - visibleTop);

        if (visibleHeight > maxVisibleHeight) {
          maxVisibleHeight = visibleHeight;
          mostVisiblePage = index + 1;
        }
      }
    });

    setPageNumber(mostVisiblePage);
  }, []);

  useEffect(() => {
    const container = containerRef.current;

    container?.addEventListener('scroll', calculateVisiblePage);

    return () => container?.removeEventListener('scroll', calculateVisiblePage);
  }, [numPages, calculateVisiblePage]);

  const scrollToPage = useCallback((pageNum: number) => {
    const targetPage = pageRefs.current[pageNum - 1];

    if (targetPage && containerRef.current) {
      containerRef.current.scrollTop = targetPage.offsetTop - 166;
    }
  }, []);

  const zoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.25, 10));
  const zoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.25, 0.1));

  const rotateClockwise = () => setRotation((prevRotation) => (prevRotation + 90) % 360);
  const rotateCounterClockwise = () => setRotation((prevRotation) => (prevRotation - 90 + 360) % 360);

  const handleFit = () => {
    if (viewerRef.current && pageRefs.current[0]) {
      const pdfContainer = viewerRef.current.querySelector('.pdf-document') as HTMLElement;

      if (!pdfContainer) {
        return;
      }

      const containerWidth = pdfContainer.offsetWidth;
      const pageWidth = pageRefs.current[0]?.offsetWidth / scale;
      const newScale = containerWidth / pageWidth;

      setScale(Math.max(newScale, 0.25));
    }
  };

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${documentName}.pdf`;
    link.click();
  };

  const toggleFullscreen = () => {
    if (viewerRef.current && screenfull.isEnabled) {
      screenfull.toggle(viewerRef.current);
      setIsFullscreen(screenfull.isFullscreen);
    } else {
      console.warn('Fullscreen not supported on this device.');
    }
  };

  const renderPage = (pageNum: number) => (
    <div
      key={`page_${pageNum}`}
      className="pdf-page"
      ref={(el) => (pageRefs.current[pageNum - 1] = el)}
      style={{ marginBottom: 16 }}
    >
      <Page
        pageNumber={pageNum}
        scale={scale}
        rotate={rotation}
        onRenderError={(error) => {
          console.error('Error rendering page:', error);
          setError('Failed to render page. Please try again.');
        }}
        renderTextLayer={true}
        renderAnnotationLayer={true}
      />
    </div>
  );

  if (error) {
    return <Typography.Text type="danger">{error}</Typography.Text>;
  }

  if (loading) {
    return <Skeleton active={true} />;
  }

  return (
    <div
      ref={viewerRef}
      style={{
        ...styleWrapper,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
        border: '1px solid #f0f0f0',
      }}
    >
      <Space
        style={{
          padding: '16px',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexWrap: 'nowrap',
          whiteSpace: 'nowrap',
          overflowX: 'auto',
          zIndex: 2,
          boxShadow: '0 2px 8px rgba(0,0,0, 0.15)',
        }}
      >
        <Tooltip title={trb('PDFViewer.PreviousPage')}>
          <Button
            type="text"
            icon={<LeftOutlined />}
            onClick={() => changePage(-1)}
            disabled={pageNumber <= 1}
          />
        </Tooltip>
        <Space>
          <Typography.Text strong={true}>
            {pageNumber} / {numPages}
          </Typography.Text>
        </Space>
        <Tooltip title={trb('PDFViewer.NextPage')}>
          <Button
            type="text"
            icon={<RightOutlined />}
            onClick={() => changePage(1)}
            disabled={pageNumber >= numPages}
          />
        </Tooltip>
        <Tooltip title={trb('PDFViewer.ZoomIn')}>
          <Button
            type="text"
            icon={<ZoomInOutlined />}
            onClick={zoomIn}
          />
        </Tooltip>
        <Tooltip title={trb('PDFViewer.Fit')}>
          <Button
            type="text"
            onClick={handleFit}
            icon={<FullscreenOutlined />}
          />
        </Tooltip>
        <Tooltip title={trb('PDFViewer.ZoomOut')}>
          <Button
            type="text"
            icon={<ZoomOutOutlined />}
            onClick={zoomOut}
          />
        </Tooltip>
        <Tooltip title={trb('PDFViewer.RotateClockwise')}>
          <Button
            type="text"
            icon={<RotateRightOutlined />}
            onClick={rotateClockwise}
          />
        </Tooltip>
        <Tooltip title={trb('PDFViewer.RotateCounterClockwise')}>
          <Button
            type="text"
            icon={<RotateLeftOutlined />}
            onClick={rotateCounterClockwise}
          />
        </Tooltip>
        {!disableDownload && (
          <Tooltip title={trb('PDFViewer.Download')}>
            <Button
              type="text"
              icon={<DownloadOutlined />}
              onClick={downloadPDF}
            />
          </Tooltip>
        )}
        {!disablePrint && (
          <Tooltip title={trb('PDFViewer.Print')}>
            <Button
              type="text"
              icon={<PrinterOutlined />}
              onClick={handlePrint}
            />
          </Tooltip>
        )}
        <Tooltip title={isFullscreen ? trb('PDFViewer.ExitFullscreen') : trb('PDFViewer.EnterFullscreen')}>
          <Button
            type="text"
            icon={<ExpandOutlined />}
            onClick={toggleFullscreen}
          />
        </Tooltip>
        <Tooltip title={isMultiPage ? trb('PDFViewer.SinglePage') : trb('PDFViewer.MultiplePage')}>
          <Button
            type="text"
            onClick={() => {
              setIsMultiPage((prevState) => {
                if (!prevState) {
                  setPageNumber(1);
                }

                return !prevState;
              });
            }}
            icon={isMultiPage ? <BorderOutlined /> : <SplitCellsOutlined style={{ transform: 'rotate(90deg)' }} />}
          />
        </Tooltip>
      </Space>
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          textAlign: 'center',
          backgroundColor: '#f0f0f0',
          padding: '16px 16px 0 16px',
          width: '100%',
        }}
        ref={containerRef}
        onDoubleClick={zoomIn}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <Document
            file={url}
            onLoadSuccess={() =>
              setTimeout(() => {
                handleFit();
              }, 10)
            }
            onLoadError={(error) => {
              console.error('Error loading PDF:', error);
              setError(trb('PDFViewer.FailedToLoadPDF'));
            }}
            className="pdf-document"
          >
            <div
              style={{
                display: 'inline-block',
              }}
            >
              {isMultiPage ? Array.from(new Array(numPages), (_, index) => renderPage(index + 1)) : renderPage(pageNumber)}
            </div>
          </Document>
        )}
      </div>
    </div>
  );
};
