export const getTrimmedValue = (value: string, trim: 'start' | 'end' | 'all'): string => {
  switch (trim) {
    case 'start':
      return value.trimStart();
    case 'end':
      return value.trimEnd();
    case 'all':
      return value.replaceAll(/\s/g, '');
    default:
      return value;
  }
};
