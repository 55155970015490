/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PAYMENT_SYSTEMS, i18 } from 'src/Utilities';
import { REGEX } from 'src/constants';
import { yup } from 'src/utils';

const checkPayerPayeeType = (v: string, paymentSystem: string, isAdditionalPaymentInformationSelected: boolean) => {
  const payerTypes = ['p', 'o'];
  return isAdditionalPaymentInformationSelected && [PAYMENT_SYSTEMS.SEPA, PAYMENT_SYSTEMS.INST].includes(paymentSystem) && payerTypes.includes(v);
};

const checkTarget2Payment = (v: string) => {
  return v === PAYMENT_SYSTEMS.TARGET2;
};

export const validation = yup.object().shape({
  Payment: yup.object().shape({
    InitialAccountID: yup
      .string()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    Recipient: yup.string().nullable().required().max(70),
    BeneficiaryAccountNumber: yup
      .string()
      .nullable()
      .required()
      .min(1)
      .test('not-equal', i18.Validations.IBANSame, function () {
        const { ValidationPayerIBAN, ValidationPayeeIBAN }: { ValidationPayerIBAN?: string; ValidationPayeeIBAN?: string } = this.parent;

        return ValidationPayerIBAN?.replaceAll(' ', '') !== ValidationPayeeIBAN?.replaceAll(' ', '');
      }),
    Amount: yup
      .number()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () =>
          yup
            .number()
            .nullable()
            .required()
            .min(0.01)
            .max(100000000000)
            .test('amountAvailable', { error: i18.Validations.InsufficientFunds }, function (value) {
              const amountAvailable = this.parent.AmountAvailable;

              return !(value && (amountAvailable || amountAvailable === 0) && value > amountAvailable);
            }),
        otherwise: () => yup.string().nullable(),
      }),
    Currency: yup
      .string()
      .nullable()
      .when('PaymentFormType', {
        is: (val: string | undefined) => val !== 'template',
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    Purpose: yup
      .string()
      .nullable()
      .required()
      .when(['PaymentSystem', 'IsPurposeSelected'], {
        is: (paymentSystem: string, purposeSelected: boolean) => checkTarget2Payment(paymentSystem) || purposeSelected,
        then: () =>
          yup
            .string()
            .required()
            .min(1)
            .max(140, { error: 'Validations.MaxPurposeInput', options: { max: 140 } }),
        otherwise: () =>
          yup
            .string()
            .required()
            .min(1)
            .max(35, { error: 'Validations.MaxPurposeCodeInput', options: { max: 35 } }),
      }),

    CustomersCode: yup.string().when('PaymentSystem', {
      // @ts-ignore
      is: checkTarget2Payment,
      then: () => yup.string().nullable(),
      otherwise: () =>
        yup
          .string()
          .nullable()
          .max(35)
          .trim()
          .matches(REGEX.CUSTOMER_CODE, { message: { error: i18.PaymensValidations.InvalidCustomersCodeError }, excludeEmptyString: true })
          .matches(REGEX.ENDS_SLASH, { message: { error: i18.PaymensValidations.InvalidCustomersCodeError }, excludeEmptyString: true }),
    }),

    TemplateName: yup
      .string()
      .nullable()
      .when(['SaveTemplate', 'PaymentFormType'], {
        is: (SaveTemplate: boolean, PaymentFormType: string) => SaveTemplate === true || PaymentFormType === 'template',
        then: () => yup.string().nullable().required().max(70),
        otherwise: () => yup.string().nullable(),
      }),

    PayerIdentTypeCode: yup
      .string()
      .nullable()
      .when(['PayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    PayerIdentTypeValue: yup
      .string()
      .nullable()
      .when(['PayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),
    PayerAddress: yup
      .string()
      .nullable()
      .when(['PayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(140),
        otherwise: () => yup.string().nullable(),
      }),
    PayerCountryCode: yup
      .string()
      .nullable()
      .when(['PayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),

    PayeeIdentTypeValue: yup
      .string()
      .nullable()
      .when(['PayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),
    PayeeIdentTypeCode: yup
      .string()
      .nullable()
      .when(['PayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    PayeeAddress: yup
      .string()
      .nullable()
      .when(['PayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(140),
        otherwise: () => yup.string().nullable(),
      }),

    InitPayerName: yup
      .string()
      .nullable()
      .when(['InitPayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(70),
        otherwise: () => yup.string().nullable(),
      }),
    InitPayerIdentTypeCode: yup
      .string()
      .nullable()
      .when(['InitPayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    InitPayerIdentTypeValue: yup
      .string()
      .nullable()
      .when(['InitPayerType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),

    FinalPayeeName: yup
      .string()
      .nullable()
      .when(['FinalPayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(70),
        otherwise: () => yup.string().nullable(),
      }),
    FinalPayeeIdentTypeCode: yup
      .string()
      .nullable()
      .when(['FinalPayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    FinalPayeeIdentTypeValue: yup
      .string()
      .nullable()
      .when(['FinalPayeeType', 'PaymentSystem', 'IsAdditionalDataSelected'], {
        // @ts-ignore
        is: checkPayerPayeeType,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),

    BeneficiaryCountryCode: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    BeneficiaryCity: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),
    BeneficiaryAddress: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),
    BeneficiaryPostCode: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().max(35),
        otherwise: () => yup.string().nullable(),
      }),
    BankName: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().max(255),
        otherwise: () => yup.string().nullable(),
      }),
    RecipientBankCountry: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required(),
        otherwise: () => yup.string().nullable(),
      }),
    BIC: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().min(8).max(11),
        otherwise: () => yup.string().nullable(),
      }),
    CorrespondentBankBic: yup
      .string()
      .nullable()
      .when('PaymentSystem', {
        // @ts-ignore
        is: checkTarget2Payment,
        then: () => yup.string().nullable().required().min(8).max(11),
        otherwise: () => yup.string().nullable(),
      }),
    ConfirmTarget2: yup.boolean().when(['PaymentFormType', 'PaymentSystem'], {
      // @ts-ignore
      is: (PaymentFormType?: string, PaymentSystem?: string) => PaymentFormType !== 'template' && checkTarget2Payment(PaymentSystem),
      then: () => yup.boolean().oneOf([true], { error: 'Validations.FieldRequiredAndApprove' }),
      otherwise: () => yup.boolean().nullable(),
    }),
  }),
});
