import { useEffect, useState } from 'react';

import { FormFieldType } from 'src/Components';
import { currencyFormat, i18, logError, numberFormat, required, requiredAndApprove, trb, checkMin, checkMax, CURRENCIES } from 'src/Utilities';
import { AMCActions } from 'src/Redux';
import { useDispatch, useSelector } from 'react-redux';
import { ForexPaymentTimer } from './ForexPaymentTimer';

const checkMinMaxValues = (currency) => (value, values, formValues) => {
  const { minAmount, maxAmount } = currency?.find(({ currency }) => currency === formValues?.Payment?.Currency) ?? {};

  if (minAmount) {
    const minError = checkMin(minAmount)(value);
    if (minError) {
      return minError;
    }
  }
  if (maxAmount) {
    const maxError = checkMax(maxAmount)(value);
    if (maxError) {
      return maxError;
    }
  }
  return null;
};
export const ForexExchangeForm = ({ accountOverdraftInfo, forexCurrency, fromAccounts, InitialAccounts, destinationAccounts, forexTimerValues }) => {
  const dispatch = useDispatch();
  const { forexRateLoader } = useSelector((state) => state.AMCReducers);

  const checkOverdraftValue = (value, values) => {
    values.Payment.OverdraftValue = `${accountOverdraftInfo?.totalAvailable ? currencyFormat(accountOverdraftInfo.totalAvailable) : 0} ${CURRENCIES.EUR}`;
  };

  const [initialAccount, setInitialAccount] = useState(null);
  const checkAccountId = (value) => {
    if (value !== initialAccount) {
      setInitialAccount(value);
    }
  };

  useEffect(() => {
    const getAccountOverdraftInfo = async (account) => {
      try {
        if (account?.hasOverdraftProduct) {
          await dispatch(AMCActions.getAccountOverdraft(initialAccount));
        } else {
          dispatch(AMCActions.setAccountOverdraft(null));
        }
      } catch (error) {
        logError(error);
      }
    };
    if (initialAccount) {
      const account = InitialAccounts.find((item) => item.value === initialAccount);
      getAccountOverdraftInfo(account);
    }
  }, [initialAccount]);

  return {
    parts: [
      {
        title: i18.PaymentParts.Payer,
        fields: [
          {
            name: 'Payment.PaymentType',
            component: FormFieldType.input,
            value: 4,
            className: 'd-none',
            defaultValue: 'forex-exchange-form',
          },
          {
            name: 'Payment.FromCurrency',
            component: FormFieldType.input,
            className: 'd-none',
            defaultValue: 'USD',
          },
          {
            name: 'Payment.canBuy',
            component: FormFieldType.input,
            value: 4,
            className: 'd-none',
            defaultValue: false,
          },
          {
            title: i18.Labels.FromAccount,
            name: 'Payment.FromAccount',
            component: FormFieldType.select,
            options: fromAccounts,
            optionsLabel: 'label',
            optionsValue: 'value',
            validateCompose: true,
            validate: [required, checkAccountId],
            className: 'mobile',
          },
          {
            title: i18.Labels.OverdraftAvailableAmount,
            name: 'Payment.OverdraftValue',
            validate: checkOverdraftValue,
            component: FormFieldType.input,
            disabled: true,
            className: initialAccount && accountOverdraftInfo?.totalAvailable ? '' : 'd-none',
          },
        ],
      },
      {
        title: i18.PaymentParts.Payee,
        fields: [
          {
            title: i18.Labels.ToAccount,
            name: 'Payment.BeneficiaryAccountNumber',
            component: FormFieldType.select,
            options: destinationAccounts,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsColor: 'color',
            optionsDisabled: 'disabled',
            validate: required,
          },
          {
            title: i18.Labels.Currency,
            name: 'Payment.Currency',
            component: FormFieldType.selectSearch,
            options:
              forexCurrency?.map(({ currency }) => ({
                label: currency,
                value: currency,
              })) ?? [],
            optionsLabel: 'label',
            optionsValue: 'value',
            allowClear: false,
            className: null,
            validate: required,
          },
        ],
      },
      { className: 'col-12 col-md-6 px-0', fields: [] },
      {
        visible: 'Payment.canBuy',
        className: 'col-12 col-md-6 px-0',
        fields: [
          {
            name: 'Payment.remove',
            component: FormFieldType.html,
            html: `<p class="eb_fs_15 mb-0">${trb(i18.Labels.CurrencyFrom)}</p>`,
            className: 'col-12 col-md-4 m-0 p-0 pl-3 mb-1 mt-sm-0 align-self-center',
          },
          {
            title: i18.Labels.Amount,
            name: 'Payment.AmountBuy',
            component: FormFieldType.number,
            className: 'col-12 col-md-12 m-0 p-0 mt-sm-0',
            classNameTitle: 'form-field-title col-12 mt-1',
            loading: forexRateLoader,
            disabled: forexRateLoader,
            validateCompose: true,
            validate: [required, checkMinMaxValues(forexCurrency)],
          },
          {
            title: i18.Labels.FixRate,
            name: 'Payment.FixRateBuy',
            component: FormFieldType.input,
            disabled: true,
            className: 'col-12 col-md-4 m-0 p-0 mt-sm-0 d-none',
          },

          {
            name: 'Payment.remove',
            component: FormFieldType.html,
            html: `<p class="eb_fs_15 mb-0 mt-2">${trb(i18.Labels.CurrencyTo)}</p>`,
            className: 'col-12 col-md-4 m-0 p-0 pl-3 mt-sm-0 align-self-center',
          },
          {
            title: i18.Labels.Amount,
            name: 'Payment.AmountSell',
            component: FormFieldType.number,
            className: 'col-12 col-md-12 m-0 p-0 mt-sm-0',
            classNameTitle: 'form-field-title col-12 mt-1',
            loading: forexRateLoader,
            disabled: forexRateLoader,
            validate: required,
          },
          {
            title: i18.Labels.FixRate,
            name: 'Payment.FixRateSell',
            component: FormFieldType.input,
            disabled: true,
            className: 'col-12 col-md-4 m-0 p-0 mt-sm-0 d-none',
          },
          {
            name: 'Payment.remove',
            component: FormFieldType.html,
            html: `<p class="eb_fs_15 mb-0 mt-2">${trb(i18.Labels.FixRate)}</p>`,
            className: 'col-12 col-md-4 m-0 p-0 pl-3 mt-sm-0 align-self-center',
          },
          {
            title: 'currencyFrom/currencyTo',
            name: 'Payment.FixRate',
            titleFromInputValues: ['Payment.FromCurrency', '/', 'Payment.Currency'],
            component: FormFieldType.number,
            formatNumber: numberFormat().coefficient6decimal,
            className: 'col-12 col-md-12 m-0 p-0 mt-sm-0',
            classNameTitle: 'form-field-title col-12 mt-1',
            disabled: true,
          },
          {
            name: 'Payment.remove',
            component: FormFieldType.html,
            html: `<p class="eb_fs_15">${trb(i18.Labels.Commission)}</p>`,
            className: 'd-none col-12 col-md-4 m-0 p-0 pl-3 mt-sm-0 align-self-center',
          },
          {
            title: i18.Labels.Commission,
            name: 'Payment.Commission',
            component: FormFieldType.number,
            className: 'd-none col-12 col-md-12 m-0 p-0 mt-sm-0',
            classNameField: 'px-1',
            disabled: true,
            format: numberFormat().currency,
          },
          {
            component: FormFieldType.component,
            reactComponent: <ForexPaymentTimer forexTimerValues={forexTimerValues} />,
            className: 'col-12 col-md-12 pt-0 pl-4',
            classNameField: 'col-12 text-justify mobile font-size-12 mt-2 pr-0',
          },
          {
            name: 'Confirm',
            component: FormFieldType.checkbox,
            translate: i18.Labels.AgreeWithForexExchange,
            validate: requiredAndApprove,
            className: 'col-12 col-md-12 pt-0 pl-4',
            classNameField: 'col-12 text-justify mobile font-size-12 mt-2 pr-0',
          },
        ],
      },
    ],
  };
};
