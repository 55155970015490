import React from 'react';
import { Checkbox } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';

import { FormFieldInputWrapperClasses, FormFieldWrapperClasses } from '../';
import { trb } from 'src/Utilities';
import { formFieldId } from 'src/utils';

export const CheckboxFormField = (props) => {
  const {
      title,
      titleWithoutTranslate,
      className,
      classNameField,
      classNameTitle,
      classNameError,
      inline,
      disabled,
      label,
      translate,
      checkedNumber,
      meta,
      customChecks,
      html,
      input,
    } = props,
    checked = checkedNumber ? input.value == 1 : !!input.value;

  return (
    <div
      id={formFieldId(input.name, 'CheckboxWrapper')}
      className={`${customChecks ? 'custom-check ' : ''}${FormFieldWrapperClasses(className, inline)}`}
    >
      {title ? (
        <div
          className={
            classNameTitle
              ? 'form-field-title ' + classNameTitle
              : inline
                ? className.indexOf('col-12') != -1
                  ? 'form-field-title col-3 text-right'
                  : 'form-field-title col-6 text-right pr-2'
                : 'form-field-title col-12'
          }
        >
          {titleWithoutTranslate ? title : trb(title)}
        </div>
      ) : (
        <div className={inline ? 'form-field-title col-3 text-right' : 'form-field-title col-12'} />
      )}
      <div className={classNameField ? 'form-field-item ' + classNameField : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, false)}>
        <Checkbox
          id={formFieldId(input.name, 'Checkbox')}
          disabled={disabled}
          checked={checked}
          onChange={(e) => input.onChange(e.target.checked)}
        >
          {customChecks && <span>{checked ? <MinusCircleFilled /> : <PlusCircleFilled />}</span>}
          <span>
            {translate ? (
              trb(translate)
            ) : html ? (
              <div
                className={`form-field-html  ${className ?? ''}`}
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              />
            ) : (
              label
            )}
          </span>
        </Checkbox>
      </div>
      <div
        id={formFieldId(input.name, 'CheckboxError')}
        className={`form-field-error ${classNameError ? classNameError : `col-12 ${inline ? 'col-md-3' : ''}`}`}
      >
        {meta.touched && (meta.error || meta.submitError)}
      </div>
    </div>
  );
};
