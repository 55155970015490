import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { i18, trb } from 'src/Utilities';
import { FormField, Select } from 'src/shared';
import { IPaymentInternationalValues } from '../..';
import { getTrimmedValue } from '../../../../../utils';

export const FieldTemplate = () => {
  const { setFieldValue, resetForm, values } = useFormikContext();
  const { PTR, forexPayments } = useSelector((state: any) => state.PMCReducers);
  const { state } = useLocation();

  useEffect(() => {
    const setTemplate = async (templateId: string) => {
      await setFieldValue('Payment.Template', templateId);
      handleChangeTemplate(templateId);
    };
    if (state?.templateId && !(values as IPaymentInternationalValues)?.Payment?.Template) {
      setTemplate(state.templateId);
    }
  }, []);

  useEffect(() => {
    if (forexPayments && state?.templateId) {
      const current = PTR?.PaymentTemplates?.find((e: any) => e.TemplateID === state.templateId);
      const RecipientBankCountry = current.Payment.RecipientBankCountry;
      if (forexPayments?.Countries?.find(({ ISO }: { ISO: string }) => ISO === RecipientBankCountry)) {
        setFieldValue('Payment.RecipientBankCountry', current.Payment.RecipientBankCountry);
      }
    }
  }, [forexPayments]);

  const handleChangeTemplate = async (id: string) => {
    if (id) {
      const current = PTR?.PaymentTemplates?.find((e: any) => e.TemplateID === id);

      await setFieldValue('Payment.Recipient', current.Payment.Recipient);
      await setFieldValue('Payment.BeneficiaryAccountNumber', getTrimmedValue(current.Payment.BeneficiaryAccountNumber, 'all'), true);
      await setFieldValue('Payment.Purpose', current.Payment.Purpose);
      await setFieldValue('Payment.BeneficiaryAddress', current.Payment.BeneficiaryAddress);
      const RecipientBankCountry = current.Payment.RecipientBankCountry;
      const RecipientBankCountryValue =
        !RecipientBankCountry || forexPayments?.Countries?.find(({ ISO }: { ISO: string }) => ISO === RecipientBankCountry) ? RecipientBankCountry : '';
      await setFieldValue('Payment.RecipientBankCountry', RecipientBankCountryValue);
      await setFieldValue('Payment.BeneficiaryCity', current.Payment.BeneficiaryCity);
      await setFieldValue('Payment.BeneficiaryPostCode', current.Payment.BeneficiaryPostCode);
      await setFieldValue('Payment.CorrespondentBankBic', current.Payment.CorrespondentBankBic);
    } else {
      resetForm();
    }
  };

  const filterPaymentTemplates = (templates: { PaymentType: number }[]) => {
    return templates.filter((e) => e.PaymentType === 7);
  };

  return (
    <div>
      <FormField
        name="Payment.Template"
        label={trb(i18.Labels.PaymentTemplates)}
      >
        <Select
          options={filterPaymentTemplates(PTR?.PaymentTemplates).map((item: any) => ({
            label: <>{item.TemplateName}</>,
            value: item.TemplateID,
            labelText: item.TemplateName,
          }))}
          handleChange={(value: string) => handleChangeTemplate(value)}
        />
      </FormField>
    </div>
  );
};
