export const stringInject = (template: string, data: any): string => {
  const regex = /\{([\w\.\[\]]+)(?:\|([\w]+))?\}/g;

  return template.replace(regex, (match, path, fallback) => {
    const value = path
      .split(/\.|\[|\]/)
      .filter(Boolean)
      .reduce((obj: any, key: string) => {
        return obj != null && obj[key] !== undefined ? obj[key] : undefined;
      }, data);

    if (value === undefined || value === null) {
      return fallback !== undefined ? fallback : match;
    }

    return String(value);
  });
};
