import React from 'react';
import { Editor } from 'draft-js';
import classNames from 'classnames';

export const ChatInput = ({ editorState, setEditorState, name = '', meta = {} }) => {
  const errorMessage = meta?.touched && (meta?.error || meta?.submitError);

  return (
    <div className="RichEditor-root row">
      <div className="col-12 form-field-item">
        <div
          id={`ChatInput${name}`}
          className={classNames('RichEditor-editor', {
            'RichEditor-editor--error': !!errorMessage,
          })}
        >
          <Editor
            editorState={editorState}
            onChange={setEditorState}
          />
        </div>
      </div>
      <div className="form-field-error col-12">
        <div className="bbs_form_error_text text-error">{errorMessage}</div>
      </div>
    </div>
  );
};
