import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { i18, PAYMENT_SYSTEMS, PaymentType, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { PAYMENT_TEMPLATE_INTERNATIONAL, PAYMENT_TEMPLATE_NEW, ViewPaymentsInternational, ViewPaymentsNew } from 'src/Containers';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PMCActions } from 'src/Redux';

export const PaymentTemplateForm = (props) => {
  const params = useParams();
  const { templateType } = params;
  const dispatch = useDispatch();

  const { forexPayments } = useSelector((state) => state.PMCReducers);

  const initialData = {
    Payment: {
      IsAdditionalDataSelected: false,
      IsPurposeSelected: true,
      PayeeName: '',
      PayeeCode: '',
      IsTemplate: true,
      InitialAccountID: '',
      DestinationAccountID: null,
      Recipient: '',
      BeneficiarysCode: '',
      Purpose: '',
      ReferenceNo: null,
      CustomersCode: null,
      SaveTemplate: true,
      PaymentHashID: null,
      PayeeType: '',
      PayerAddress: '',
      PayeeIdentTypeValue: '',
      PayeeAddress: '',
      PayerCountryCode: null,
      PayeeCountryCode: null,
      DocumentNumber: null,
      PayeeAddressChk: false,
      PayerAddressChk: false,
      CustCodeChk: true,
      RecipientBankName: null,
      PayerName: '',
      PayerAgreementIdentTypeCode: 'u',
      IsSEPAPaymentTypeSelected: false,
      SEPAPaymentType: null,
      InitPayerName: null,
      InitPayerType: '',
      InitPayerIdentTypeValue: null,
      InitPayerAddressChk: null,
      InitPayerAddress: null,
      InitPayerCountryCode: null,
      FinalPayeeName: null,
      FinalPayeeType: '',
      FinalPayeeIdentTypeValue: null,
      FinalPayeeAddress: null,
      FinalPayeeAddressChk: null,
      FinalPayeeCountryCode: null,
      PayerType: '',
      PaymentFormType: 'template',
      BeneficiaryAddress: '',
      BeneficiaryCity: '',
      BeneficiaryCountryCode: '',
      BeneficiaryPostCode: '',
      CorrespondentBankBic: '',
      AmountStr: '1',
    },
  };

  const getInitialData = (paymentType) => {
    return {
      ...initialData,
      Payment: {
        ...initialData.Payment,
        PaymentSystem: paymentType === PaymentType.INTERNATIONAL ? PAYMENT_SYSTEMS.INTERNATIONAL : PAYMENT_SYSTEMS.SEPA,
        PaymentType: paymentType,
        ...props?.data?.Payment,
        BeneficiaryAccountNumber: props?.data?.Payment?.BeneficiaryAccountNumber ?? '',
        TemplateName: props?.data?.TemplateName || null,
        TemplateID: props?.data?.TemplateID || null,
        CheckBeneficiaryAccountNumber: true,
      },
      TemplateName: props?.data?.TemplateName || null,
      TemplateID: props?.data?.TemplateID,
    };
  };
  const routePath = `${routerPath.payments}/${routerPath.paymentTemplates}/${routerPath.paymentTemplatesNew}`;

  useEffect(() => {
    if (!forexPayments && templateType === PAYMENT_TEMPLATE_INTERNATIONAL) {
      dispatch(PMCActions.getForexPayments());
    }
  }, []);

  return (
    <div className="w-100">
      {!props.editMode && (
        <div className="col-12 mb-3">
          <NavLink
            className="form-title mr-3 text-gray-basic"
            to={routePath.replace(':templateType', PAYMENT_TEMPLATE_NEW)}
          >
            {trb(i18.PaymentTypes.NewPayment)}
          </NavLink>
          <NavLink
            className="form-title mr-3 text-gray-basic "
            to={routePath.replace(':templateType', PAYMENT_TEMPLATE_INTERNATIONAL)}
            onClick={() => {
              if (!forexPayments) {
                dispatch(PMCActions.getForexPayments());
              }
            }}
          >
            {trb(i18.ContentMenu.InternationalPayment)}
          </NavLink>
        </div>
      )}
      {templateType === PAYMENT_TEMPLATE_NEW && (
        <ViewPaymentsNew
          data={getInitialData(PaymentType.SEPA)}
          submit={props.submit}
          handleBackClick={props.clickBack}
        />
      )}
      {templateType === PAYMENT_TEMPLATE_INTERNATIONAL && (
        <ViewPaymentsInternational
          data={getInitialData(PaymentType.INTERNATIONAL)}
          submit={props.submit}
          handleBackClick={props.clickBack}
        />
      )}
      {props.errors && (
        <Alert
          className="mb-3 col-12"
          message={trb(i18.ErrorTitles.SaveTemplateErrors)}
          description={
            <ul>
              {props.errors.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          }
          type="error"
        />
      )}
    </div>
  );
};
