import { AlertNotification } from 'src/shared';
import { translationGroups, trb } from 'src/Utilities';
import { IPaymentError } from './interfaces';
import { useEffect, useRef } from 'react';

interface IProps {
  errors?: Array<IPaymentError> | null;
}

export const PaymentError = ({ errors }: IProps) => {
  const errorRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (errorRef && errors) {
      errorRef.current?.scrollIntoView();
    }
  }, [errors]);

  return errors ? (
    <div
      ref={errorRef}
      style={{ scrollMarginTop: '16px' }}
    >
      {errors.map((item: IPaymentError, index: number) => (
        <div
          className="mb-3"
          key={index}
        >
          <AlertNotification
            message={trb(translationGroups.ErrorCode + item.ErrorCode)}
            type="error"
            alertClassName=""
            description={
              item.ErrorCode === 'KycMustUpdated' || item.ErrorCode === 'Forex.F001' ? (
                <div
                  className="alert_description"
                  dangerouslySetInnerHTML={{ __html: trb(translationGroups.ErrorCode + 'KycMustUpdatedDescription') }}
                />
              ) : null
            }
          />
        </div>
      ))}
    </div>
  ) : null;
};
