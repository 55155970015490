export const paymentNewValues = {
  Payment: {
    Amount: null,
    BeneficiaryAccountNumber: '',
    Recipient: '',
    SaveTemplate: false,
    TemplateName: '',
    IsPurposeSelected: true,
    InitialAccountID: '',
    PaymentSystem: 'SepaSct',
    IsAdditionalDataSelected: false,
    Currency: 'EUR',
    Purpose: '',
    ReferenceNo: '',
    CustomersCode: '',
    PayerType: '',
    PayeeType: '',
    InitPayerType: '',
    InitPayerIdentTypeCode: '',
    FinalPayeeIdentTypeCode: '',
    PayerIdentTypeCode: '',
    PayerIdentTypeValue: '',
    PayerAddress: '',
    PayerCountryCode: '',
    PayeeIdentTypeValue: '',
    PayeeIdentTypeCode: '',
    PayeeAddress: '',
    InitPayerName: '',
    InitPayerIdentTypeValue: '',
    FinalPayeeType: '',
    FinalPayeeName: '',
    FinalPayeeIdentTypeValue: '',
    BeneficiaryCountryCode: '',
    BeneficiaryCity: '',
    BeneficiaryAddress: '',
    BeneficiaryPostCode: '',
    BankName: '',
    RecipientBankCountry: '',
    BIC: '',
    CorrespondentBankBic: '',
    ConfirmTarget2: false,
    AmountAvailable: null,
    OverdraftValue: null,
  },
};
