import { Col, Row } from 'antd';
import { Formik, Form } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { INewMandate, BeneficiariesActions, BeneficiariesConstants, PMCActions } from 'src/Redux';
import { i18, trb, translationGroups } from 'src/Utilities';
import { Button, FormField, Input } from 'src/shared';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/Config';

import { Notification } from 'src/Components';

const MANDATE_VALID_BIC = 'USDFLT22XXX';

interface IProps {
  initialValues: INewMandate;
  onSubmit: (values: INewMandate) => void;
}

export const BeneficiariesNewForm = ({ initialValues, onSubmit }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { language } = useSelector((state: any) => state.languageReducer);
  const { signMandateInfo } = useSelector((state: any) => state.BeneficiariesReducers);

  const [isLoading, setIsLoading] = useState(false);
  const [IBANError, setIBANError] = useState<string | null>(null);
  const [IBANVAlid, setIBANValid] = useState(false);
  const [checkingIBAN, setCheckingIBAN] = useState(false);

  const handleSubmit = async (values: INewMandate) => {
    setIsLoading(true);
    onSubmit(values);

    const { type, payload } = await dispatch(
      BeneficiariesActions.postCreateNewMandate({
        ...values,
        CreditAccount: values.CreditAccount.replaceAll(' ', ''),
      }) as any
    );

    if (type === BeneficiariesConstants.POST_CREATE_NEW_MANDATE_SUCCESS) {
      if (!payload.IsSuccess) {
        setIBANValid(false);
        setIBANError(trb(translationGroups.ErrorDescriptions + payload.ErrorMessage));
      } else {
        const { payload: payloadSign } = await dispatch(BeneficiariesActions.postSignMandate({ ProductId: payload.Token, Language: language }) as any);

        if (!payloadSign.IsSuccess) {
          setIBANValid(false);
          Notification({
            type: 'warning',
            message: i18.NotificationTitles.PostSignMandateError,
            description: i18.NotificationMessages.SignMandateError,
          });
        }
      }
    }

    setIsLoading(false);
  };

  const handleValidationIBAN = async (value: string) => {
    if (value) {
      const resValid = await dispatch(PMCActions.postCheckIBANMember(value.replaceAll(' ', '')) as any);

      setCheckingIBAN(false);

      if (resValid?.payload?.Bic?.BIC === MANDATE_VALID_BIC) {
        setIBANError(null);
        setIBANValid(true);
      } else {
        setIBANError(i18.ErrorDescriptions.CreditorAccountIBANInvalid);
      }
    }
  };

  const validateIBANAfterTO = debounce((valueInDebounce: string) => {
    handleValidationIBAN(valueInDebounce);
  }, 800);

  const handleIbanChanged = (value: string) => {
    if (value) {
      setCheckingIBAN(true);
      setIBANValid(false);
      validateIBANAfterTO(value);
    }
  };

  const backToList = () => {
    navigate(`${routerPath.payments}/${routerPath.beneficiaries}`);
  };

  useEffect(() => {
    if (initialValues.CreditAccount) {
      handleIbanChanged(initialValues.CreditAccount);
    }
  }, [initialValues.CreditAccount]);

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ values, isSubmitting }) => (
        <Form
          noValidate={true}
          autoComplete="off"
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>{<div className="eb_subtitle p-0">{trb(i18.Labels.NewBeneficiary)}</div>}</Col>
            <Col
              md={12}
              xs={24}
            >
              <FormField
                name="CreditorTitle"
                label={trb(i18.Labels.CreditorTitle)}
                disabled={isSubmitting}
              >
                <Input />
              </FormField>
            </Col>
            <Col
              md={12}
              xs={24}
            >
              <FormField
                name="CreditAccount"
                label={trb(i18.Labels.CreditorAccountNumber)}
                error={IBANError}
                disabled={isSubmitting}
              >
                <Input
                  handleChange={handleIbanChanged}
                  trimOnBlur="all"
                />
              </FormField>
            </Col>
            <Col span={24}>
              <Row
                gutter={[8, 0]}
                justify="end"
              >
                <Col>
                  <Button
                    onClick={backToList}
                    disabled={isSubmitting}
                  >
                    {trb(i18.Buttons.Cancel)}
                  </Button>
                </Col>

                <Col>
                  <Button
                    htmlType="submit"
                    type="primary"
                    disabled={!values.CreditorTitle || !values.CreditAccount || checkingIBAN || !IBANVAlid || isSubmitting || !!signMandateInfo}
                    loading={isLoading}
                  >
                    {trb(i18.Buttons.Approve)}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};
